/* COLORS */

/* open-sans-regular - cyrillic_latin */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('../fonts/opensans/open-sans-v15-cyrillic_latin-regular.woff2') format('woff2'),  url('../fonts/opensans/open-sans-v15-cyrillic_latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - cyrillic_latin */

@font-face {
  font-family: 'Open Sans SemiBold';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url('../fonts/opensans/open-sans-v15-cyrillic_latin-600.woff2') format('woff2'),  url('../fonts/opensans/open-sans-v15-cyrillic_latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.series {
  display: flex;
}

.series__pic {
  flex-basis: 650px;
  position: relative;
}

.series__picture-wrapper {
  position: relative;
}

.series__picture-wrapper:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #F5F5F5;
  position: absolute;
  top: 30px;
  right: -30px;
  z-index: -1;
}

.series__text-side {
  margin-top: 15%;
}

.description {
  position: relative;
  margin-top: 307px;
}

.description__bg {
  background: black;
  display: flex;
}

.description__text-container {
  width: 100%;
  height: 100%;
  flex-basis: 55%;
  flex-grow: 1;
}

.description__text {
  color: #AAAAAA;
  font-size: 18px;
  line-height: 30px;
  padding: 30% 0;
  max-width: 310px;
  margin-left: 15%;
  position: relative;
}

.description__text::after {
  content: "";
  display: block;
  width: 140px;
  height: 1px;
  background: white;
  position: relative;
  left: 0;
  top: 100px;
}

.description__header {
  position: absolute;
  font-size: 165px;
  font-weight: bold;
  left: 15%;
  top: -145px;
  z-index: -1;
  text-transform: uppercase;
}

.description__picture {
  flex-basis: 45%;
  flex-grow: 1;
  position: relative;
}

.description__picture-img {
  position: absolute;
  top: -115px;
  right: 0;
}

.wantadress {
  display: flex;
  margin-top: 200px;
}

.wantadress__picture {
  flex-basis: 45%;
  flex-grow: 1;
  position: relative;
}

.wantadress__picture-wrapper {
  position: relative;
}

.wantadress__picture-wrapper:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #F5F5F5;
  position: absolute;
  top: 30px;
  right: -30px;
  z-index: -1;
}

.wantadress__picture-img {
  position: relative;
  z-index: 1;
}

.wantadress__icon {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 1;
  width: 70px;
  opacity: 0.3;
  display: none;
}

.wantadress__icon--bride {
  filter: brightness(30%);
}

.wantadress__text {
  flex-basis: 55%;
  flex-grow: 1;
  position: relative;
}

.wantadress__text-container {
  font-size: 18px;
  line-height: 30px;
  padding: 20% 0;
  max-width: 310px;
  margin-left: 15%;
}

.wantadress__volifert {
  position: absolute;
  font-size: 165px;
  font-weight: bold;
  line-height: 1.2em;
  color: #E6E6E6;
  bottom: -156px;
  left: 100px;
  text-transform: uppercase;
}

.dresses {
  width: 100%;
  box-sizing: border-box;
  margin-top: 250px;
  margin-bottom: -20px;
  overflow: hidden;
  position: relative;
}

.dresses__pic {
  transition: filter 0.5s 'ease-in-out';
  filter: brightness(30%);
}

.dresses__pic:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.dresses__gallery {
  width: 10%;
  position: absolute;
  left: 45%;
  top: 45%;
  z-index: 1;
  opacity: 0.5;
  text-align: center;
  transition: opacity 0.3s 'ease-in-out';
}

.dresses__gallery:hover {
  cursor: pointer;
}

.dresses__more {
  width: 75%;
}

.dresses__text {
  color: white;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.fullscreen-slider {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  display: none;
  background: #000000;
}

.fullscreen-slider__img {
  max-height: 100vh;
  margin: 0 auto;
}

.fullscreen-slider__slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 50px;
  height: 100vh;
  content: 'viewport-units-buggyfill; height: 100vh;';
}

.fullscreen-slider__item-wrapper {
  padding: 0 5%;
}

.fullscreen-slider__wrapper {
  width: 100%;
}

.fullscreen-slider__close {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 5%;
  top: 5%;
}

.fullscreen-slider__next-arrow {
  position: relative;
  width: 220%;
  height: 80px;
  display: block;
  opacity: 0.5;
  z-index: 999999;
  transition: opacity 0.3s 'ease-in-out';
}

.fullscreen-slider__next-arrow::after {
  content: '';
  position: absolute;
  background-color: white;
  top: 25%;
  width: 50px;
  height: 5px;
  right: 0;
  transform: rotate(45deg);
}

.fullscreen-slider__next-arrow::before {
  content: '';
  position: absolute;
  top: 65%;
  background-color: white;
  width: 50px;
  height: 5px;
  right: 0;
  transform: rotate(-45deg);
}

.fullscreen-slider__next-arrow:hover {
  opacity: 0.7;
  cursor: pointer;
}

.fullscreen-slider__prev-arrow {
  position: relative;
  width: 220%;
  height: 80px;
  display: block;
  opacity: 0.5;
  z-index: 999999;
  transition: opacity 0.3s 'ease-in-out';
}

.fullscreen-slider__prev-arrow::after {
  content: '';
  position: absolute;
  background-color: white;
  top: 25%;
  width: 50px;
  height: 5px;
  transform: rotate(-45deg);
}

.fullscreen-slider__prev-arrow::before {
  content: '';
  position: absolute;
  top: 65%;
  background-color: white;
  width: 50px;
  height: 5px;
  transform: rotate(45deg);
}

.fullscreen-slider__prev-arrow:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .series__pic {
    flex-basis: 50%;
  }

  .series__picture-wrapper:after {
    top: 20px;
    right: -20px;
  }

  .series__text-side {
    margin-top: 10%;
  }

  .description__text {
    padding: 25% 0;
  }

  .description__text::after {
    top: 50px;
  }

  .description__header {
    font-size: 115px;
    top: -106px;
  }

  .description__picture-img {
    top: -86px;
  }

  .wantadress__picture {
    flex-basis: 50%;
  }

  .wantadress__picture-wrapper:after {
    top: 20px;
    right: -20px;
  }

  .wantadress__text-container {
    padding: 10% 0;
  }

  .wantadress__volifert {
    font-size: 115px;
    bottom: -108px;
  }

  .dresses {
    margin-top: 150px;
  }
}

@media screen and (max-width: 1024px) {
  .description__header {
    font-size: 80px;
    top: -73px;
  }

  .description__picture-img {
    top: -60px;
  }

  .wantadress__volifert {
    font-size: 80px;
    bottom: -75px;
  }

  .dresses {
    margin-top: 100px;
  }

  .fullscreen-slider__slider {
    margin: 0 20px;
  }
}

@media screen and (max-width: 568px) {
  .series {
    margin-top: 46px;
    flex-direction: column;
  }

  .series__pic {
    flex-basis: 100%;
  }

  .series__picture-wrapper:after {
    display: none;
  }

  .description {
    margin-top: 200px;
  }

  .description__bg {
    flex-direction: column;
  }

  .description__text-container {
    order: 2;
    margin-top: 60%;
  }

  .description__text {
    margin: 0 5% 0 5%;
    padding-bottom: 30%;
    font-size: 16px;
    line-height: 25px;
  }

  .description__header {
    display: none;
  }

  .description__picture {
    order: 1;
    margin: 0 15px 0 15px;
  }

  .description__picture-img {
    top: -120px;
  }

  .wantadress {
    flex-direction: column;
    margin-top: 100px;
    padding-bottom: 40px;
  }

  .wantadress__picture-wrapper:after {
    display: none;
  }

  .wantadress__icon {
    display: block;
  }

  .wantadress__text-container {
    margin: 0 5% 0 5%;
  }

  .wantadress__volifert {
    font-size: 75px;
    bottom: auto;
    top: -71px;
    left: 15%;
  }

  .dresses {
    display: none;
    margin-top: 50px !important;
  }

  .fullscreen-slider__slider {
    margin: 0;
  }

  .fullscreen-slider__item-wrapper {
    padding: 0 3%;
  }
}